import type { AccountTypes, Group, Member, StudentViewStates, User } from 'types';

export function userHasRole(user: User, role: AccountTypes): boolean {
  const accounts = user?.accounts;
  if (!accounts) return false;

  return !!accounts.find(account => account.type === role);
}

export function isUserEducator(user: User): boolean {
  return userHasRole(user, 'educator');
}

export function isUserLeadAccountType(user: User): boolean {
  return userHasRole(user, 'lead');
}

export function isMemberGroupLead(group: Group, studentView?: StudentViewStates) {
  if (studentView === 'true') return false;
  return group?.user_grid?.role === 'owner' || group?.user_grid?.role === 'co_owner';
}

export function isMembershipGroupLead(membership: Member | null, studentView?: StudentViewStates) {
  if (studentView === 'true') return false;
  return membership?.role === 'owner' || membership?.role === 'co_owner';
}

export function isMemberGroupOwner(group: Group, studentView?: StudentViewStates) {
  if (studentView === 'true') return false;
  return group?.user_grid?.role === 'owner';
}

export function userBelongsToGroup(group: Group) {
  const { membership } = group;
  return !membership?.blocked && (isMemberGroupLead(group) || membership?.role === 'member');
}
